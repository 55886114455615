<template>
  <div>
    <div class="btn" @click="showkehu">
      <span class="font1">支付宝</span>
      <span class="font2">二维码</span>
    </div>
    <footer class="zwzweb-footer">
      <section class="wrapper component-pageContentArea">
        <div class="phoneWrapper">
          <img
            class="phone"
            src="@assets/images/phone.png"
          />
          <div class="phoneRight">
            <p class="time">客服热线（9:30-18:00）</p>
            <p class="number">15606999783</p>
          </div>
        </div>
        <span class="flexg"></span>
        <div class="right">
          <router-link to="/" class="link">首页</router-link>
          <router-link to="/product" class="link">商品中心</router-link>
          <router-link to="/collaborate" class="link">商家入驻</router-link>
          <router-link to="/about" class="link">关于我们</router-link>
          <p class="email">电子邮箱：anzuji@jpnettech.com</p>
          <p>地址：福建省福州市台江区富力中心B1</p>
        </div>
      </section>
      <a
        id="beianhao-anchor"
        target="_blank"
        href="https://beian.miit.gov.cn"
        class="icp"
        >闽ICP备18018987号-1</a
      >
    </footer>
    <div v-if="isShow" class="pop" style="z-index:2000">
        <div class="pop_header">
            <div class="title">支付宝</div>
            <img
              @click="isShow = false"
              class="closepng"
              src="@assets/images/close.png"
            />
        </div>
        <div class="pop_main">
            <img
              src="@assets/images/qrcode.png"
              alt="anzhuji"
            />
        </div>
        <div class="pop_bottom">
           支付宝扫码
        </div>
    </div>
    <div class="modal" @click="isShow = false" v-if="isShow"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    showkehu() {
      this.isShow = true
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  position: fixed;
  z-index: 120;
  right: 0;
  top: 56%;
  width: 193px;
  height: 83px;
  background: linear-gradient(90deg, #ff808b 0%, #ed0013 100%);
  border-radius: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  .font1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 42px;
  }
  .font2 {
    font-size: 16px;
    font-weight: 500;
  }
}
.component-pageContentArea {
  padding: 0 348px;
  display: flex;
  justify-content: space-between;
}
.flexg {
  flex-grow: 1;
}
.zwzweb-footer {
  width: 100%;
  height: 291px;
  background: #130e0e;
  .phoneWrapper {
    color: #fff;
    display: flex;
    align-items: center;
    .phone {
      width: 64px;
      height: 64px;
      margin-right: 18px;
    }
    .time {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .number {
      font-size: 28px;
      font-weight: bold;
    }
  }
  .right {
    color: #fff;
    font-size: 20px;
    font-weight: 500;

    .link {
      text-decoration: none;
      color: #fff;
      font-size: 26px;
      &:hover {
        color: red;
      }
    }
    .link + .link {
      margin-left: 96px;
    }
    .email {
      margin-top: 47px;
      margin-bottom: 11px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    height: 255px;
    position: relative;
  }
  .icp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background: #130e0e;
  }
  a {
    text-decoration: none;
  }
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index:150;
}

.closepng {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
.pop{
  position: fixed;
  background: #fff;
  width: 350px;
  height: 350px;
  top:200px;
  left: 50%;
  transform: translateX(-50%);
  z-index:200;
  border-radius: 10px;
  color: #333;
  .pop_header{
      width: 360px;
      position: relative;
      height: 80px;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title{
        font-size: 20px;
        letter-spacing: 5px;
        font-weight: bold;
      }
  }
  .pop_main{
    text-align: center;
    height: 220px;
    img{
      width: 220px;
      height: 100%;
    }
  }
  .pop_bottom{
        font-size: 20px;
        letter-spacing: 5px;
        text-align: center; 
        margin-top: 5px;
        margin-bottom: 5px;
   }
}
</style>