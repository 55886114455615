<template>
  <div id="app">
     <Nav @handlePath="handlePath" v-if="$route.meta.show"></Nav>
     <router-view/>
     <Footer v-if="$route.meta.show"/>
  </div>
</template>
<script>
import Nav from "@/components/nav";
import Footer from "@/components/footer";
export default {

 components:{
   Nav,Footer
 },
 watch:{
   '$route':function(ti,from){
      document.querySelector('html').scrollTop=0
   }
 },
 methods:{
   handlePath(path){
     this.$router.push(path)
   }
 }
  
}
</script>
<style lang="scss" >
#app{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.box {
    flex-grow: 1;
}
body{
      font-family: PingFang-SC-Medium, PingFang-SC, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}
.component-img-area{
    background: transparent;
}
.component-pageContentArea{
    padding: 0 348px;
    display: flex;
    justify-content: space-between;   
}
  .flexcc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
  }
.componentUnitTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
  .line {
    width: 42px;
    height: 4px;
    background: #000;
    margin-bottom: 10px;
  }
  .unitTitle {
    font-size: 40px;
    font-weight: 500;
    color: #333;
    margin-bottom:4px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      right: -16px;
      top: 20px;
      background: #ddd;
    }
  }
  .titleEn {
    font-size: 18px;
    color: #999;
    font-weight: 500;
    letter-spacing: 12px;
  }
}
</style>
