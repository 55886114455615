<template>
  <div class="box">
    <div class="banner">
      <img
        class="bg"
        src="@/assets/images/p1.png"
      />
      <div class="Wrapper">
        <div class="flexcc">
          <img
            class="qrCode"
            src="@/assets/images/qrcode.png"
          />
          <span class="title">支付宝扫码换新机</span>
        </div>
      </div>
    </div>
    <!-- 第二 -->
    <div class="componentUnitTitleWrapper">
      <span class="line"></span>
      <span class="unitTitle">安租机</span>
      <span class="titleEn">为您提供全面保障</span>
    </div>
    <!-- 第三 -->
    <div class="advantArea">
      <div class="featureItem" v-for="item in advantage" :key="item.id" :class="{'featureItem1':item.id>2}">
        <div class="featureImg component-img-area">
          <img :src="item.img" class="img" />
        </div>
        <div class="featureText">
          <span>{{ item.name }}</span
          ><span class="line"></span><span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <!-- 第四 -->
    <div class="gifWrapper">
      <div class="component-img-area">
        <img
          src="@assets/images/gif_1.gif"
          class="img"
        />
      </div>
      <div class="qrCodeWrapper">
        <section class="flexcc">
          <img
            class="qrCode"
            src="@/assets/images/qrcode.png"
          /><span class="slogan2 fontStyle">支付宝扫码换新机</span>
        </section>
      </div>
      <div class="block1 block"></div>
      <div class="block2 block"></div>
      <div class="title1">年年换新机，就上安租机</div>
      <div class="titleEn">NEW PHONE FOR NEW YEAR，NEW PHONE ON ZUWUZU</div>
    </div>
    <!-- 第五 -->
    <div class="componentUnitTitleWrapper">
      <span class="line"></span>
      <span class="unitTitle">合作商户</span>
      <span class="titleEn">我们的战略合作伙伴</span>
    </div>
    <section class="component-pageContentArea">
      <section class="partnerImg component-img-area" v-for="item in partner" :key="item.id">
        <img
          :src="item.img"
          class="img"
        />
      </section>
    </section>
    <section class="component-pageContentArea">
      <section class="partnerImg component-img-area" v-for="item in partner1" :key="item.id">
        <img
          :src="item.img"
          class="img"
        />
      </section>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advantage: [
        {
          id: 1,
          img:require("@/assets/images/zhenping.png"),
          name: "正品国行",
          text: "顺丰发货",
        },
        {
          id: 2,
          img: require("@/assets/images/mianya.png"),
          name: "信用免押",
          text: "价格优惠",
        },
        {
          id: 3,
          img: require('@/assets/images/dijia.png'),
          name: "售后无忧",
          text: "意外保障",
        },
        {
          id: 4,
          img:require('@/assets/images/anquan.png'),
          name: "司法存证",
          text: "隐私安全",
        },
      ],
      partner:[
      {id:1,img:require('@/assets/images/pabuershuju.png'),},
      {id:2,img:require('@/assets/images/paeqianbao.png'),},
      {id:3,img:require('@/assets/images/pamayihuabei.png')},
      ],
      partner1:[
      {id:1,img:require('@/assets/images/pamayijinfu.png')},
      {id:2,img:require('@/assets/images/pazhifubao.png')},
      {id:3,img:require('@/assets/images/pazhimaxinyong.png')},
      ]
    };
    
  },
  mounted(){
        this.scrollReveal().reveal('.featureItem', {
            reset: true, // 滚动鼠标时，动画开关
            origin: 'left', // 动画开始的方向
            duration: 1500, // 动画持续时间
            delay: 0, // 延迟
            rotate: {x:200, y:100, z:0}, // 过度到0的初始角度
            opacity: 0.3, // 初始透明度
            scale: 0.4, //缩放
            distance: '1000px',
            easing: 'ease', // 缓动'ease', 'ease-in-out'，'linear'...
        });
        this.scrollReveal().reveal('.featureItem1', {
            reset: true, // 滚动鼠标时，动画开关
            origin: 'right', // 动画开始的方向
            duration: 1500, // 动画持续时间
            delay: 0, // 延迟
            rotate: {x:200, y:100, z:0}, // 过度到0的初始角度
            opacity: 0.3, // 初始透明度
            scale: 0.4, //缩放
            distance: '1000px',
            easing: 'ease', // 缓动'ease', 'ease-in-out'，'linear'...
        });
  }
};
</script>

<style lang="scss" scoped>

.banner {
  position: relative;
  margin-bottom: 69px;
  .bg {
    display: block;
    width: 100%;
    min-height: 870px;
    height: auto;
  }
  .Wrapper {
    position: absolute;
    top: 561px;
    left: 50%;
    transform: translateX(-50%);
    .flexcc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      .qrCode {
        width: 180px;
        height: 180px;
        margin-bottom: 4px;
      }
      .title {
        font-size: 20px;
        font-weight: 800;
      }
    }
  }
}
.advantArea {
  padding: 0 348px;
  display: flex;
  justify-content: space-between;
  .featureItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 200px;
    .featureImg {
      display: block;
      width: 215px;
      height: 215px;
      margin-bottom: 21px;
      background: transparent;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .featureText {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #333;
    font-weight: 500;
    .line {
      width: 2px;
      height: 12px;
      background: #333;
      margin: 0 10px;
    }
  }
}
.gifWrapper {
  position: relative;
  height: 634px;
  margin-bottom: 36px;
  .component-img-area {
    background: transparent;
    img {
      position: absolute;
      top: 0;
      left: 456px;
      width: 290px;
      height: 598px;
    }
  }
  .qrCodeWrapper {
    position: absolute;
    left: 1197px;
    right: 516px;
    top: 179px;
      .qrCode {
        width: 180px;
        height: 180px;
        margin-bottom: 4px;
      }
      .fontStyle {
        color: #333;
        font-size: 20px;
        font-weight: 500;
      }
    
  }
  .block1 {
    width: 939px;
    height: 251px;
    left: 0;
    top: 142px;
  }
  .block2 {
    width: 1023px;
    height: 243px;
    right: 0;
    top: 212px;
  }
  .block {
    position: absolute;
    z-index: -1;
    background: #f2f2f2;
  }
  .title1 {
    font-size: 40px;
    color: #333;
    font-weight: 500;
    line-height: 56px;
    position: absolute;
    left: 1080px;
    top: 33px;
    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #ddd;
      top: 46px;
      left: -34px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #ddd;
      top: 24px;
      left: -14px;
      z-index: -1;
    }
  }
  .titleEn {
    position: absolute;
    font-size: 20px;
    color: #999;
    font-weight: 400;
    left: 1137px;
    top: 91px;
  }
}
.partnerImg {
    display: block;
    width: 209px;
    height: 95px;
    margin-bottom: 32px;
    .img {
        display: block;
        width: 100%;
        height: 100%;
    }
} 


</style>