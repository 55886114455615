<template>
    <div class="header">
          <div class="nav">
                <div class="logo">
                     <img class="logo_pic" src="@assets/images/azjLogo.png">
                     <span>年年换新机</span>
                     <span style="flex-grow: 1;"></span>
                </div>
                <div class="navList">
                     <div class="item"
                     @click="handleMenu(item)"
                     :class="{'isActive':item.id == clickId}"
                      v-for="item in navList" 
                      :key="item.id">{{item.name}}</div>
                </div>
          </div>
    </div>
</template>

<script>
export default {
  data(){
      return{
          clickId:1,
           navList:[
                {id:1,name:"首页",link:"/"},
                {id:2,name:"商品中心",link:"/product"},
                {id:3,name:"商家入驻",link:"/collaborate"},
                {id:4,name:"关于我们",link:"/about"},
        ]
      }
  },
  mounted(){
      this.navList.map(ele=>{
           if(ele.link == this.$route.path){
                this.clickId=ele.id
           }
      })
  },
  methods:{
        handleMenu(item){
             this.clickId = item.id
             this.$emit('handlePath',item.link)
        }
  },
}
</script>

<style lang="scss" scoped>
.isActive{
    color: red;
}

.header{
    color: #fff;
    height: 90px;
    position: fixed;
    z-index: 100;
    width: 100%;
    background: rgba(0,0,0,0.7);
    .nav{
        display: flex;
        align-items: center;
        padding: 0 348px;
        height: 100%;
        justify-content: space-between;
    .logo{
        font-size: 16px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .logo_pic{
            width: 154px;
            height: 40px;
             margin-right: 11px;
        }
    }
    .navList{
        display: flex;
        align-items: center;
        font-size: 20px;
        white-space: nowrap;
        .item{
            cursor: pointer;
            margin-left:48px;
            &:hover{
            color: red;}
        }
    }
   }

}
</style>