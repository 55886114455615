<template>
  <div class="box">
    <div class="collaborate-title">入驻信息</div>
    <div class="collaborate-subtitle">
      请填写您的联系方式，我们会及时联系您！期待与您的合作
    </div>
    <div class="collaborate-form">
      <div class="collaborate-form-content">
        <div class="message hidden"><div class="message-content"></div></div>
        <div class="collaborate-form-item">
          <div class="collaborate-form-item-label user-name">姓 名：</div>
          <input class="name-input" placeholder="请输入您的姓名" value="" />
        </div>
        <div class="collaborate-form-item">
          <div class="collaborate-form-item-label">联系方式：</div>
          <input
            class="phone-input"
            placeholder="请输入您的联系方式"
            value=""
          />
        </div>
        <div class="collaborate-form-footer">
          <div class="submit-btn">
            <div class="submit-btn-text">提<span class="gap">交</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
  
    }
  },
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
.collaborate-title {
    font-size: 70px;
    font-weight: bold;
    color: #333;
    letter-spacing: 10px;
    margin-top: 190px;
    text-align: center;
}
.collaborate-subtitle {
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin-top: 33px;
    text-align: center;
}
.collaborate-form {
    width: 1133px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgb(155 155 155 / 50%);
    border-radius: 20px;
    margin: 33px auto 64px;
    padding-top: 77px;
    padding-bottom: 55px;
    .collaborate-form-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 748px;
        margin: auto;
    }
}
.message {
    z-index: 99;
    position: absolute;
    top: 68px;
    color: #fff;
    left: 80px;
    text-align: center;
}
.hidden {
    display: none !important;
}
.message-content {
    width:592px;
    height: 87px;
    line-height: 87px;
    background: rgba(0,0,0,0.7);
    border-radius: 16px;
    margin: auto;
    font-size: 24px;
    font-weight: 500;
}
.collaborate-form-item-label {
    text-align: right;
    display: inline-block;
    position: relative;
    bottom: -50px;
    font-size: 24px;
}
.user-name {
    left: 0;
    letter-spacing: 5px;
}
.name-input,.phone-input{
    font-size: 24px;
    width: 748px;
    height: 68px;
    border-radius: 10px;
    border: 1px solid #999999;
    z-index: 333;
    text-indent: 20px;    
}
.collaborate-form-footer {
    margin-top: 33px;
}
.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 475px;
    height: 68px;
    line-height: 68px;
    background: linear-gradient(90deg, #FF808B 0%, #ED0013 100%);
    border-radius:34px;
    margin: auto;
    cursor: pointer;
}
.submit-btn-text {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    line-height: 37px;
}
.gap {
    margin-left: 50px;
}
</style>