import Vue from 'vue'
import VueRouter from 'vue-router'
import  Index  from '../views/home.vue'
import Collaborate from '../views/collaborate.vue'
import Product from '../views/product.vue'
import About from '../views/about.vue'
import NotFound from '../views/404.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta:{title:'首页',show:true}
  },
  {
    path: '/index',
    name: 'home',
    component: Index,
    meta:{title:'首页',show:true}
  },
  {
    path:"/product",
    name:"product",
    component:Product,
    meta:{title:"商品中心",show:true},
  },
  {
    path:"/collaborate",
    name:"Collaborate",
    component:Collaborate,
    meta:{title:"商家入驻",show:true}
  },{
    path:"/about",
    name:"about",
    component:About,
    meta:{title:"关于我们",show:true}
  },{
    path:"*",
    component:NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: './',
  routes
})
router.beforeEach((to,path,next)=>{
    if(to.meta&&to.meta.title){
        document.title ='安租机_'+to.meta.title
    }
    next()
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default router
