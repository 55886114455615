<template>
  <div class="box">
    <div class="bannerWrapper">
      <img
        class="banner"
        src="@assets/images/detailBanner2NoQrCode.png"
      />
      <div class="pcOrPhoneWrapper">
        <section class="avatar2BtnPCWrapper flexcc">
          <img
            class="qrCode"
            src="@/assets/images/qrcode.png"
          /><span class="slogan2 undefined">支付宝扫码换新机</span>
        </section>
      </div>
    </div>
    <section class="componentUnitTitleWrapper" style="margin-bottom: 0.9rem">
      <span class="line"></span>
      <span class="unitTitle">怎么租？</span>
      <span class="titleEn">HOW CAN I RENT A MACHINE</span>
    </section>
    <div class="text-center">
      <img
        src="@/assets/images/Group 4@2x (1).png"
        class="rent-steps pc"
        alt=""
      />
    </div>
    <div class="bottom-qrcode">
      <section class="avatar2BtnPCWrapper flexcc">
        <img
          class="qrCode"
          src="@/assets/images/qrcode.png"
        /><span class="slogan2 undefined">支付宝扫码换新机</span>
      </section>
    </div>
    <div class="rent-machines">
      <div class="rent-machines-header">
        <section class="componentUnitTitleWrapper" >
          <span class="line"></span>
          <span class="unitTitle">抢租商品</span>
          <span class="titleEn">RENTAL MACHINES</span>
        </section>
      </div>
      <div class="rent-machines-content">
        <div class="rent-machines-offset">
          <div class="rent-machine-item" v-for="item in iphonesList" :key="item.id">
            <div class="item-img">
              <section class="product-img component-img-area">
                <img
                  :src="item.img"
                  class="img"
                />
              </section>
            </div>
            <div class="item-name">{{item.productName}}</div>
            <div class="item-price">
              <span class="item-price-unit">¥</span
              ><span class="item-price-num">{{item.price}}</span
              ><span class="item-price-day">/天起</span>
            </div>
            <div class="item-qrcode">
              <section class="avatar2BtnPCWrapper flexcc">
                <img
                  class="qrCode"
                  src="@/assets/images/qrcode.png"
                /><span class="slogan2 undefined">支付宝扫码换新机</span>
              </section>
            </div>
            <div class="item-go-app hidden">
              <span class="app-desc">进入<br />支付宝小程序<br />换新机</span>
              <div class="go-btn">点击进入</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      iphonesList:[
        {
          id:1,
          img:require('@/assets/images/1-iPhone12jpg.jpg'),
          productName:"Iphone 12",
          price:10.99
        },
        {
          id:2,
          img:require('@/assets/images/2-华为mate40Pro.jpg'),
          productName:"华为Mate 40",
          price:18.99
        },
        {
          id:3,
          img:require("@/assets/images/3-Switch游戏机.jpg"),
          productName:"Switch 游戏机",
          price:4.5
        },
        {
          id:4,
          img:require("@/assets/images/4-MacBook-Pro-15.4.jpg"),
          productName:"MacBook Pro",
          price:5.2
        },
        {
          id:5,
           img:require("@/assets/images/5-佳能200d.jpg"),
          productName:"佳能200D",
          price:15
        },
        {
          id:6,
          img:require("@/assets/images/6-AirPods Pro.jpg"),
          productName:"AirPods Pro",
          price:3.8
        },
        {
          id:7,
          img:require("@/assets/images/7-大疆御air.png"),
          productName:"大疆御 无人机",
          price:20
        },
        {
          id:8,
          img:require("@/assets/images/8-惠普打印机.jpeg"),
          productName:"惠普打印机",
          price:2
        },
        {
          id:9,
         img:require("@/assets/images/9-DW腕表.jpeg"),
          productName:"DW腕表",
          price:2.3
        },
      ],
      scrollReveal:this.scrollReveal()
    }
  },
  mounted(){
    this.scrollReveal.reveal('.rent-machine-item', {
            reset: false, // 滚动鼠标时，动画开关
            origin: 'left', // 动画开始的方向
            duration: 1000, // 动画持续时间
            delay: 0, // 延迟
            rotate: {x:200, y:100, z:0}, // 过度到0的初始角度
            opacity: 0.3, // 初始透明度
            scale: 0.6, //缩放
            distance: '1000px',
            easing: 'ease', // 缓动'ease', 'ease-in-out'，'linear'...
        });
  },
  methods:{

  }
};
</script>

<style lang="scss" scoped>
.box {
  flex-grow: 1;
}
.bannerWrapper {
  position: relative;
  margin-bottom: 69px;
  .banner {
    display: block;
    width: 100%;
    height: auto;
  }
  .pcOrPhoneWrapper {
    position: absolute;
    left: 1170px;
    top: 666px;
    transform: translateX(0);
    .qrCode {
      width: 180px;
      height: 180px;
      margin-bottom: 4px;
    }
    .slogan2 {
      color: #333;
      font-size: 20px;
      font-weight: 500;
    }
  }
}
.text-center {
  text-align: center;
  .pc {
    width: 1200px;
    height: 102px;
  }
}
.bottom-qrcode {
  margin-top: 88px;
  margin-bottom: 103px;
  .qrCode {
    width: 180px;
    height: 180px;
    margin-bottom: 4px;
  }
  .slogan2 {
    color: #333;
    font-size: 20px;
    font-weight: 500;
  }
}
.rent-machines{
  margin-bottom: 77px;
  .rent-machines-header {
      display: inline-block;
      width: 100%;
      height: 293px;
      background-image:url('@assets/images/pexels-fauxels-3184454@2x (1).png');
      background-size: 1920px 293px;
    .componentUnitTitleWrapper {
        margin-bottom:90px;
        padding-top: 41px;
       .line{
         background: #fff;
       }
       .unitTitle{
         color: #fff;
       }
       .titleEn{
          color: #fff;
       }
    }
  }
  .rent-machines-content{
    background: #fff;
    padding: 0 348px;
    margin-top: -100px;  
    .rent-machines-offset {
        display: flex;
        flex-wrap: wrap;
        color: #000;
    }  
  }
  .rent-machine-item {
      width: 398px;
      height: 460px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px rgb(155 155 155 / 50%);
      border-radius: 20px;
      margin-right: 3px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &:hover{
        .item-qrcode{
          display: block;
        }
      }
      .item-img {
          width: 366px;
          height: 330px;
          .product-img {
              display: block;
              width: 100%;
              height: 100%;
              .img {
                  display: block;
                  width: 100%;
                  height: 100%;
              }
          }
      }
      .item-name{
        font-size: 20px;
        font-weight: 549;
        color: #999;
        margin-top: 20px;        
      }
      .item-price {
          display: flex;
          align-items: baseline;
          font-size: 20px;
          color: #E10617;
          margin-top: 10px;

      }
      .item-price-num {
          font-size: 40px;
          font-weight:500;
      }
      .item-qrcode{
          width:398px;
          height: 460px;
          background: rgba(0,0,0,0.7);
          position: absolute;
          display: none;
          z-index: 99;
          border-radius: 20px;
          .qrCode {
              width: 180px;
              height: 180px;
              margin-bottom:4px;
          }
          .flexcc {
              margin-top:108px;
          }
          .slogan2 {
              font-size: 26px;
              font-weight: bold;
              padding-top: 40px;
              color: #fff;
          }
      }
      .item-go-app{
        color: #fff;
        padding:60px 23px;
        box-sizing: border-box;
        margin: auto;
        font-weight: 500;
        text-align: center;
        font-size: 45px;
        width: 398px;
        height:460px;
        background: rgba(0,0,0,0.7);
        position: absolute;
        display: none;
        z-index: 99;
        border-radius: 20px;
        .app-desc {
            line-height: 1.6;
        }
        .go-btn {
            border-radius: 27px;
            border: 2px solid #fff;
            margin-top: 50px;
        }
    }
  }
  .hidden {
    display: none !important;
   }
}
</style>