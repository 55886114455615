<template>
  <div class="box">
    <img
      class="banner"
      src="@/assets/images/p1.png"
    />
    <div class="bgfff">
      <section class="paddingWithBg componentUnitTitleWrapper">
        <span class="line"></span>
        <span class="unitTitle">关于我们</span
        ><span class="titleEn">ABOUT US</span>
      </section>
    </div>
    <section class="bgfff component-pageContentArea">
      <div class="aboutWrapper">
        <div class="topRow">
          <span class="title">安租机</span
          ><span class="value">— 聚品（福建）网络科技有限公司</span>
        </div>
        <div class="bottomRow">
          安租机专注租赁平台，为爱生活、爱科技的客户群体提供手机、
          电脑、数码、娱乐设备的租赁服务。平台对接了丰富的租赁商家，
          并打通芝麻信用，让用户年年换新机，低成本享受品质生活。
        </div>
      </div>
      <img
        class="descIcon"
        src="@/assets/images/u120@2x (1).png"
      />
    </section>
    <div class="gap77"></div>
    <div class="contractWrapper">
      <div class="contractContainer">
        <section class="componentUnitTitleWrapper" >
          <span class="line"></span><span class="unitTitle">联系我们</span
          ><span class="titleEn">CONTACT US</span>
        </section>
      </div>
      <section class="component-pageContentArea">
        <div class="phoneWrapper">
          <div class="left phoneWrapperItem">
            <section class="phoneItemImg component-img-area">
              <img
                src="@/assets/images/phone (1).png"
                class="img"
              />
            </section>
            <span class="phoneItemContent1">客服电话</span
            ><a class="phoneItemContent2 phone2" href="tel:15606999783"
              >15606999783</a
            >
          </div>
          <div class="right phoneWrapperItem">
            <section class="phoneItemImg component-img-area">
              <img
                src="@/assets/images/clock.png"
                class="img"
              />
            </section>
            <span class="phoneItemContent1">服务时间</span
            ><span class="phoneItemContent2">工作日 9:30-18:00</span>
          </div>
        </div>
      </section>
      <div class="qrWrapper">
        <section class="avatar2BtnPCWrapper flexcc">
          <img
            class="qrCode"
            src="@/assets/images/qrcode.png"
          /><span class="slogan2 pcFont">支付宝扫码换新机</span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.banner {
  display: block;
  width: 100%;
  min-height: 597px;
  height: auto;
}
.paddingWithBg {
  padding: 90px;
  background: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.component-pageContentArea {
  padding: 0 348px;
  display: flex;
  justify-content: space-between;
  .aboutWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .topRow {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      color: #333;
      .title {
        font-size: 44px;
        font-weight: bold;
        margin-right: 13px;
      }
      .value {
        font-size: 36px;
        font-weight: 500;
      }
    }
    .bottomRow {
      width: 580px;
      font-size: 20px;
      line-height: 28px;
      color: #666;
      font-weight: 400;
    }
  }
  .descIcon {
    display: block;
    width: 476px;
    height: 249px;
  }
}
.gap77 {
  height: 130px;
  background: #fff;
}
 .contractWrapper {
    box-sizing: border-box;
    padding-top: 56px;
    width: 100%;
    height: 653px;
    background: #f2f2f2;
    margin-bottom:208px;
    position: relative;
    .contractContainer {
        position: relative;
        z-index: 1;
    }
    .phoneWrapper {
        width: 100%;
        height: 261px;
        border-radius: 20px;
        background: #fff;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 186px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .phoneWrapperItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            .phoneItemImg {
                display: block;
                width:61px;
                height: 61px;
                margin-bottom: 18px;
                .img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .phoneItemContent1 {
                font-size: 20px;
                color: #333;
                font-weight: 400;
                line-height:28px;
                margin-bottom: 12px;
            }
            .phone2 {
                position: relative;
                z-index: 5;
                cursor: pointer;
            }
            .phoneItemContent2 {
                font-size: 24px;
                color: #333;
                font-weight: bold;
                text-decoration: none;
            }
            a {
                text-decoration: none;
            }
        }
    }
    .qrWrapper {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 523px;
        .qrCode {
            width:180px;
            height:180px;
            margin-bottom:4px;
        }
        .pcFont {
            color: #333;
        }
    }
}
.avatar2BtnPCWrapper .slogan2 {
    font-size: 20px;
    font-weight: 800;
}
.componentUnitTitleWrapper {
    margin-bottom: 56px
}
.component-pageContentArea {
    padding: 0 348px;
    display: flex;
    justify-content: space-between;
}
</style>